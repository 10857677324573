import { GridRow, GridColumn, Grid, Form, Input, Label, Header } from 'semantic-ui-react';
import './App.css';
import { useEffect, useState } from 'react';

function App() {
  /** APS */
  const [apsAnnualSalary, setApsAnnualSalary] = useState(100000);
  const [apsSuperRate, setApsSuperRate] = useState(15.4);
  const [apsDaysPerWeek, setApsDaysPerWeek] = useState(5);
  const [apsHoursPerDay, setApsHoursPerDay] = useState(7.5);
  const [apsAnnualLeave, setApsAnnualLeave] = useState(20);
  const [apsCarersSickLeave, setApsCarersSickLeave] = useState(18);
  const [apsChristmasShutdown, setApsChristmasShutdown] = useState(5);
  const [apsPublicHolidays, setApsPublicHolidays] = useState(10);

  /** Contractor */
  const [contHourlyRate, setContHourlyRate] = useState(100);
  const [contSuperRate, setContSuperRate] = useState(11.5);
  const [contDaysPerWeek, setContDaysPerWeek] = useState(5);
  const [contHoursPerDay, setContHoursPerDay] = useState(8);
  const [contAnnualLeave, setContAnnualLeave] = useState(20);
  const [contCarersSickLeave, setContCarersSickLeave] = useState(18);
  const [contChristmasShutdown, setContChristmasShutdown] = useState(5);
  const [contPublicHolidays, setContPublicHolidays] = useState(10);

  const updateInput = (field, value) => {
    if (!value) value = '0';
    if (value.endsWith('.')) {
      switch (field) {
        case 'apsAnnualSalary':
          setApsAnnualSalary(value);
          break;
        case 'apsSuperRate':
          setApsSuperRate(value);
          break;
        case 'apsDaysPerWeek':
          setApsDaysPerWeek(value);
          break;
        case 'apsHoursPerDay':
          setApsHoursPerDay(value);
          break;
        case 'apsAnnualLeave':
          setApsAnnualLeave(value);
          break;
        case 'apsCarersSickLeave':
          setApsCarersSickLeave(value);
          break;
        case 'apsChristmasShutdown':
          setApsChristmasShutdown(value);
          break;
        case 'apsPublicHolidays':
          setApsPublicHolidays(value);
          break;
        case 'contHourlyRate':
          setContHourlyRate(value);
          break;
        case 'contSuperRate':
          setContSuperRate(value);
          break;
        case 'contDaysPerWeek':
          setContDaysPerWeek(value);
          break;
        case 'contHoursPerDay':
          setContHoursPerDay(value);
          break;
        case 'contAnnualLeave':
          setContAnnualLeave(value);
          break;
        case 'contCarersSickLeave':
          setContCarersSickLeave(value);
          break;
        case 'contChristmasShutdown':
          setContChristmasShutdown(value);
          break;
        case 'contPublicHolidays':
          setContPublicHolidays(value);
          break;
        default:
          console.log('nothing to update');
      }
    } else {
      switch (field) {
        case 'apsAnnualSalary':
          setApsAnnualSalary(parseFloat(value));
          break;
        case 'apsSuperRate':
          setApsSuperRate(parseFloat(value));
          break;
        case 'apsDaysPerWeek':
          setApsDaysPerWeek(parseFloat(value));
          break;
        case 'apsHoursPerDay':
          setApsHoursPerDay(parseFloat(value));
          break;
        case 'apsAnnualLeave':
          setApsAnnualLeave(parseFloat(value));
          break;
        case 'apsCarersSickLeave':
          setApsCarersSickLeave(parseFloat(value));
          break;
        case 'apsChristmasShutdown':
          setApsChristmasShutdown(parseFloat(value));
          break;
        case 'apsPublicHolidays':
          setApsPublicHolidays(parseFloat(value));
          break;
        case 'contHourlyRate':
          setContHourlyRate(parseFloat(value));
          break;
        case 'contSuperRate':
          setContSuperRate(parseFloat(value));
          break;
        case 'contDaysPerWeek':
          setContDaysPerWeek(parseFloat(value));
          break;
        case 'contHoursPerDay':
          setContHoursPerDay(parseFloat(value));
          break;
        case 'contAnnualLeave':
          setContAnnualLeave(parseFloat(value));
          break;
        case 'contCarersSickLeave':
          setContCarersSickLeave(parseFloat(value));
          break;
        case 'contChristmasShutdown':
          setContChristmasShutdown(parseFloat(value));
          break;
        case 'contPublicHolidays':
          setContPublicHolidays(parseFloat(value));
          break;
        default:
          console.log('nothing to update');
      }
      recalculate();
    }
  };

  const calculateTaxOnIncome = (amount) => {
    if (amount < 18200) return 0;
    else if (amount < 45000) return 0.16 * (amount - 18200);
    else if (amount < 135000) return 4288 + 0.3 * (amount - 45000);
    else if (amount < 190000) return 31288 + 0.37 * (amount - 135000);
    else return 51638 + 0.45 * (amount - 190000);
  };

  const calculateTaxOnSuper = (amount) => {
    if (amount < 30000) return amount * 0.15;
    else return 0.15 * 30000 + 0.3 * (amount - 30000);
  };

  const calculatedResult = {
    apsGrossIncomeIncSuper: 0,
    apsGrossIncomeExcSuper: 0,
    apsGrossSuper: 0,
    apsTaxLiability: 0,
    apsTaxLiabilitySuper: 0,
    apsMedicareLevy: 0,
    apsNetIncome: 0,
    apsNetSuper: 0,
    apsNetEarnings: 0,
    apsEffHourlyRateIncSuper: 0,
    apsEffHourlyRateIncSuperAfterTax: 0,
    apsTotalHoursWorked: 0,
    apsTotalWeeksWorked: 0,
    apsTotalDaysWorked: 0,
    contGrossIncomeIncSuper: 0,
    contGrossIncomeExcSuper: 0,
    contGrossSuper: 0,
    contTaxLiability: 0,
    contTaxLiabilitySuper: 0,
    contMedicareLevy: 0,
    contNetIncome: 0,
    contNetSuper: 0,
    contNetEarnings: 0,
    contEffHourlyRateIncSuper: 0,
    contEffHourlyRateIncSuperAfterTax: 0,
    contTotalHoursWorked: 0,
    contTotalWeeksWorked: 0,
    contTotalDaysWorked: 0
  };

  const recalculate = () => {
    if (apsHoursPerDay === 0 || apsDaysPerWeek === 0) {
      calculatedResult.apsGrossIncomeExcSuper = 0;
      calculatedResult.apsGrossSuper = 0;
      calculatedResult.apsGrossIncomeIncSuper = 0;
      calculatedResult.apsTaxLiability = 0;
      calculatedResult.apsMedicareLevy = 0;
      calculatedResult.apsTaxLiabilitySuper = 0;
      calculatedResult.apsNetIncome = 0;
      calculatedResult.apsNetSuper = 0;
      calculatedResult.apsNetEarnings = 0;
      calculatedResult.apsTotalHoursWorked = 0;
      calculatedResult.apsTotalDaysWorked = 0;
      calculatedResult.apsTotalWeeksWorked = 0;
      calculatedResult.apsEffHourlyRateIncSuper = 0;
      calculatedResult.apsEffHourlyRateIncSuperAfterTax = 0;
    } else {
      let effectiveApsAnnualSalary = (apsAnnualSalary / 5 / 7.5) * apsDaysPerWeek * apsHoursPerDay;
      calculatedResult.apsGrossIncomeExcSuper = effectiveApsAnnualSalary;
      calculatedResult.apsGrossSuper = effectiveApsAnnualSalary * (apsSuperRate / 100);
      calculatedResult.apsGrossIncomeIncSuper = effectiveApsAnnualSalary + calculatedResult.apsGrossSuper;
      calculatedResult.apsTaxLiability = calculateTaxOnIncome(calculatedResult.apsGrossIncomeExcSuper);
      calculatedResult.apsMedicareLevy = calculatedResult.apsGrossIncomeExcSuper * 0.02;
      calculatedResult.apsTaxLiabilitySuper = calculateTaxOnSuper(calculatedResult.apsGrossSuper);
      calculatedResult.apsNetIncome = calculatedResult.apsGrossIncomeExcSuper - calculatedResult.apsTaxLiability - calculatedResult.apsMedicareLevy;
      calculatedResult.apsNetSuper = calculatedResult.apsGrossSuper - calculatedResult.apsTaxLiabilitySuper;
      calculatedResult.apsNetEarnings = calculatedResult.apsNetIncome + calculatedResult.apsNetSuper;

      calculatedResult.apsTotalHoursWorked = apsHoursPerDay * apsDaysPerWeek * 52 - apsHoursPerDay * (apsAnnualLeave + apsCarersSickLeave + apsPublicHolidays + apsChristmasShutdown);
      calculatedResult.apsTotalDaysWorked = apsDaysPerWeek * 52 - (apsAnnualLeave + apsCarersSickLeave + apsPublicHolidays + apsChristmasShutdown);
      calculatedResult.apsTotalWeeksWorked = calculatedResult.apsTotalDaysWorked / 5;

      calculatedResult.apsEffHourlyRateIncSuper = calculatedResult.apsGrossIncomeIncSuper / calculatedResult.apsTotalHoursWorked;
      calculatedResult.apsEffHourlyRateIncSuperAfterTax = calculatedResult.apsNetEarnings / calculatedResult.apsTotalHoursWorked;
    }

    if (contHoursPerDay === 0 || contDaysPerWeek === 0) {
      calculatedResult.contGrossIncomeIncSuper = 0;
      calculatedResult.contGrossSuper = 0;
      calculatedResult.contGrossIncomeExcSuper = 0;
      calculatedResult.contTaxLiability = 0;
      calculatedResult.contMedicareLevy = 0;
      calculatedResult.contTaxLiabilitySuper = 0;
      calculatedResult.contNetIncome = 0;
      calculatedResult.contNetSuper = 0;
      calculatedResult.contNetEarnings = 0;

      calculatedResult.contTotalHoursWorked = 0;
      calculatedResult.contTotalDaysWorked = 0;
      calculatedResult.contTotalWeeksWorked = 0;

      calculatedResult.contEffHourlyRateIncSuper = 0;
      calculatedResult.contEffHourlyRateIncSuperAfterTax = 0;
    } else {
      calculatedResult.contGrossIncomeIncSuper = contHourlyRate * contHoursPerDay * (contDaysPerWeek * 52 - (contAnnualLeave + contCarersSickLeave + contChristmasShutdown + contPublicHolidays));
      calculatedResult.contGrossSuper = (calculatedResult.contGrossIncomeIncSuper * contSuperRate) / 100;
      calculatedResult.contGrossIncomeExcSuper = calculatedResult.contGrossIncomeIncSuper - calculatedResult.contGrossSuper;
      calculatedResult.contTaxLiability = calculateTaxOnIncome(calculatedResult.contGrossIncomeExcSuper);
      calculatedResult.contMedicareLevy = calculatedResult.contGrossIncomeExcSuper * 0.02;
      calculatedResult.contTaxLiabilitySuper = calculateTaxOnSuper(calculatedResult.contGrossSuper);
      calculatedResult.contNetIncome = calculatedResult.contGrossIncomeExcSuper - calculatedResult.contTaxLiability - calculatedResult.contMedicareLevy;
      calculatedResult.contNetSuper = calculatedResult.contGrossSuper - calculatedResult.contTaxLiabilitySuper;
      calculatedResult.contNetEarnings = calculatedResult.contNetIncome + calculatedResult.contNetSuper;

      calculatedResult.contTotalHoursWorked = contHoursPerDay * contDaysPerWeek * 52 - contHoursPerDay * (contAnnualLeave + contCarersSickLeave + contPublicHolidays + contChristmasShutdown);
      calculatedResult.contTotalDaysWorked = contDaysPerWeek * 52 - (contAnnualLeave + contCarersSickLeave + contPublicHolidays + contChristmasShutdown);
      calculatedResult.contTotalWeeksWorked = calculatedResult.contTotalDaysWorked / 5;

      calculatedResult.contEffHourlyRateIncSuper = calculatedResult.contGrossIncomeIncSuper / calculatedResult.contTotalHoursWorked;
      calculatedResult.contEffHourlyRateIncSuperAfterTax = calculatedResult.contNetEarnings / calculatedResult.contTotalHoursWorked;
    }
  };

  useEffect(() => {
    setApsAnnualLeave((apsDaysPerWeek * apsHoursPerDay * 20) / (5 * 7.5));
    setApsCarersSickLeave((apsDaysPerWeek * apsHoursPerDay * 18) / (5 * 7.5));
  }, [apsDaysPerWeek, apsHoursPerDay]);

  recalculate();

  return (
    <div className='App'>
      <div>
        <Grid columns={2} stackable>
          <GridRow>
            <GridColumn className='Spacing-top Spacing-column Background-input'>
              <h1>Input</h1>
              <hr />
              <Form className='Spacing-top'>
                <Grid centered={true} columns={3} divided>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'></GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <label className='Spacing-label'>
                        <b>APS</b>
                      </label>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <b>Contractor</b>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>Annual Salary (ex. Super)</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      <Input labelPosition='right' type='text' placeholder='Amount'>
                        <Input icon='dollar' iconPosition='left' value={apsAnnualSalary} onChange={(e) => updateInput('apsAnnualSalary', e.target.value)} />
                      </Input>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      -
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>Hourly rate (inc. Super)</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      -
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Input labelPosition='right' type='text' placeholder='Amount'>
                        <Input icon='dollar' iconPosition='left' value={contHourlyRate} onChange={(e) => updateInput('contHourlyRate', e.target.value)} />
                      </Input>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>Super %age</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      <Input value={apsSuperRate} onChange={(e) => updateInput('apsSuperRate', e.target.value)} />
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Input value={contSuperRate} onChange={(e) => updateInput('contSuperRate', e.target.value)} />
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>No of days / week</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      <Input value={apsDaysPerWeek} onChange={(e) => updateInput('apsDaysPerWeek', e.target.value)} />
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Input value={contDaysPerWeek} onChange={(e) => updateInput('contDaysPerWeek', e.target.value)} />
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>Hours / day</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      <Input value={apsHoursPerDay} onChange={(e) => updateInput('apsHoursPerDay', e.target.value)} />
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Input value={contHoursPerDay} onChange={(e) => updateInput('contHoursPerDay', e.target.value)} />
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>Annual Leave</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      <Input value={apsAnnualLeave} disabled onChange={(e) => updateInput('apsAnnualLeave', e.target.value)} />
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Input value={contAnnualLeave} onChange={(e) => updateInput('contAnnualLeave', e.target.value)} />
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>Personal / Carer's Leave</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      <Input value={apsCarersSickLeave} disabled onChange={(e) => updateInput('apsCarersSickLeave', e.target.value)} />
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Input value={contCarersSickLeave} onChange={(e) => updateInput('contCarersSickLeave', e.target.value)} />
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>Christmas Shutdown</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      <Input value={apsChristmasShutdown} onChange={(e) => updateInput('apsChristmasShutdown', e.target.value)} />
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Input value={contChristmasShutdown} onChange={(e) => updateInput('contChristmasShutdown', e.target.value)} />
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4'>No. Public Holidays</Header>
                    </GridColumn>
                    <GridColumn centered='true' textAlign='center' className='Spacing-column'>
                      <Input value={apsPublicHolidays} disabled onChange={(e) => updateInput('apsPublicHolidays', e.target.value)} />
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Input value={contPublicHolidays} disabled onChange={(e) => updateInput('contPublicHolidays', e.target.value)} />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Form>
            </GridColumn>
            <GridColumn className='Spacing-top Spacing-column Background-comparison'>
              <h1 className='Color-white'>Comparison</h1>
              <hr />
              <div className='Spacing-top'>
                <Grid centered={true} columns={3} divided>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'></GridColumn>
                    <GridColumn textAlign='center' className='Color-white Spacing-column'>
                      <b>APS</b>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Color-white Spacing-column'>
                      <b>Contractor</b>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Gross Income (inc. super)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsGrossIncomeIncSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contGrossIncomeIncSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Taxable Income (ex. super)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsGrossIncomeExcSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contGrossIncomeExcSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Taxable Super
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsGrossSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contGrossSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <hr color='#eee' width='90%' />
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Tax payable (on Taxable Income)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsTaxLiability.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contTaxLiability.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Tax payable (on Super)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsTaxLiabilitySuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contTaxLiabilitySuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Medicare Levy
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsMedicareLevy.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contMedicareLevy.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <hr color='#eee' width='90%' />
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Net Income (after tax & levy)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsNetIncome.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contNetIncome.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Net super (after tax)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsNetSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contNetSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Net Total Earnings
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsNetEarnings.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contNetEarnings.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Net Monthly Total Earnings
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {(calculatedResult.apsNetEarnings / 12).toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {(calculatedResult.contNetEarnings / 12).toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Net Mon. Inc (after tax, levy ex super)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {(calculatedResult.apsNetIncome / 12).toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {(calculatedResult.contNetIncome / 12).toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>

                  <hr color='#eee' width='90%' />

                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Gross Hourly Rate (inc super)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsEffHourlyRateIncSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contEffHourlyRateIncSuper.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Net Hourly Rate (inc super, after deductions)
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsEffHourlyRateIncSuperAfterTax.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contEffHourlyRateIncSuperAfterTax.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>

                  <hr color='#eee' width='90%' />

                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Total hours worked
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsTotalHoursWorked.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contTotalHoursWorked.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Total days worked
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsTotalDaysWorked.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contTotalDaysWorked.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow className='Spacing-row'>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h4' inverted color='white'>
                        Total weeks worked
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.apsTotalWeeksWorked.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                    <GridColumn textAlign='center' className='Spacing-column'>
                      <Header as='h3' inverted color='white'>
                        {calculatedResult.contTotalWeeksWorked.toLocaleString('en-AU', { maximumFractionDigits: 2 })}
                      </Header>
                    </GridColumn>
                  </GridRow>
                  <GridRow></GridRow>
                </Grid>
              </div>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={16} textAlign='center' className='Spacing-column'>
              <Grid centered>
                <GridRow centered textAlign='center'>
                  <br />
                  FY 2024-2025 Tax rates:
                  <br />
                  $0-$18,200 - Nil
                  <br />
                  $18,200-$45,000 = 16c for each $1 over $18,200
                  <br />
                  $45,000-$135,000 = $4,288 + 30c for each $1 over $45,000
                  <br />
                  $135,000-$190,000 = $31,288 + 37c for each $1 over $135,000
                  <br />
                  $190,000 and over = $51,638 + 45c for each $1 over $190,000
                  <br />
                </GridRow>
                <GridRow textAlign='left'>
                  <Header as='h4'>
                    Super is calculated @ 11.5%
                    <br />
                    Super taxed at 15% upto 30,000. Thereafter taxed @ 30%
                  </Header>
                </GridRow>
                <GridRow textAlign='left'>
                  <Header as='h4'>Medicare Levy is calculated @ 2% of taxable income (excl super)</Header>
                </GridRow>
              </Grid>
            </GridColumn>
            <GridColumn width={16} textAlign='center' className='Spacing-column'>
              This site and information here is not endorsed by any government agency or accountant.
              <br />
              Techbraid or any of its associates are not responsible for any calculation errors or interpretations of this data.
              <br />
              contact: support@techbraid.com
            </GridColumn>
          </GridRow>
        </Grid>
      </div>
    </div>
  );
}

export default App;
